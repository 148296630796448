.thermostat-icon {
    background-image: url('../../../../../assets/images/thermostat-pointer.png');
    width: 23px;
    height: 23px;
    background-size: contain;
    background-repeat: no-repeat;
}

.MuiAvatar-root {
    margin-left: 2px;
    margin-right: -4px;
}
