.Subscriptions {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
  padding: 50px;
}

.Subscriptions-col {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
}

.Subscriptions-data {
  font-size: 0.5em;
  text-align: left;
}
