/*.container {*/
/*    width: 300px; !* Adjust the width as needed *!*/
/*}*/

.pair {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.left {
    flex: 1;
    text-align: left;
}

.right {
    flex: 1;
    text-align: right;
}
