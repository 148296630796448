/*@import '/client/assets/styles/variables.import.less';*/

/*--------------------------------------------------------------------*/
/*Operating Mode/State Icons*/

.zone-hvac-off,
.zone-hvac-off-off {
  background-image: url('../../../../../assets/images/system-off.png');
}

.zone-hvac-auto,
.zone-hvac-auto-off {
  background-image: url('../../../../../assets/images/auto-idle.png');
}

.zone-hvac-heat,
.zone-hvac-heat-off {
  background-image: url('../../../../../assets/images/heating-idle.png');
}

.zone-hvac-cool,
.zone-hvac-cool-off {
  background-image: url('../../../../../assets/images/cooling-idle.png');
}

.zone-hvac-auto-heating,
.zone-hvac-auto-cooling {
  background-image: url('../../../../../assets/images/auto.png');
}

.zone-hvac-heat-heating,
.zone-hvac-heat-cooling {
  background-image: url('../../../../../assets/images/heating.png');
}

.zone-hvac-cool-heating,
.zone-hvac-cool-cooling {
  background-image: url('../../../../../assets/images/cooling.png');
}

/*--------------------------------------------------------------------*/
/*Fan Mode/State Icons*/

.zone-fan-cycle-modbus-off {
  background-image: url('../../../../../assets/images/fan-modbus-off.png');
}

.zone-fan-cycle-modbus-on {
  background-image: url('../../../../../assets/images/fan-modbus-on.png');
}

.zone-fan-cycle-on-on {
  background-image: url('../../../../../assets/images/fan-on-on.png');
}

.zone-fan-cycle-on-off {
  background-image: url('../../../../../assets/images/fan-on-off.png');
}

.zone-fan-cycle-high-off,
.zone-fan-speed-high-off {
  background-image: url('../../../../../assets/images/fan-high-off.png');
}

.zone-fan-cycle-high-on,
.zone-fan-speed-high-on {
  background-image: url('../../../../../assets/images/fan-high-on.png');
}

.zone-fan-cycle-medium-off,
.zone-fan-speed-medium-off {
  background-image: url('../../../../../assets/images/fan-medium-off.png');
}

.zone-fan-cycle-medium-on,
.zone-fan-speed-medium-on {
  background-image: url('../../../../../assets/images/fan-medium-on.png');
}

/*Reusing medium icon for moderate setting*/
.zone-fan-cycle-moderate-off {
  background-image: url('../../../../../assets/images/fan-medium-off.png');
}

.zone-fan-cycle-moderate-on {
  background-image: url('../../../../../assets/images/fan-medium-on.png');
}

.zone-fan-cycle-low-off,
.zone-fan-speed-low-off {
  background-image: url('../../../../../assets/images/fan-low-off.png');
}

.zone-fan-cycle-low-on,
.zone-fan-speed-low-on {
  background-image: url('../../../../../assets/images/fan-low-on.png');
}

.zone-fan-cycle-auto-on {
  background-image: url('../../../../../assets/images/fan-auto-on.png');
}

.zone-fan-cycle-auto-off,
.zone-fan-speed-auto-off {
  background-image: url('../../../../../assets/images/fan-auto-off.png');
}

.zone-fan-auto-off {
  background-image: url('../../../../../assets/images/fan-auto-off.png');
}

.zone-fan-speed-auto-high {
  background-image: url('../../../../../assets/images/fan-auto-high.png');
}

.zone-fan-speed-auto-medium {
  background-image: url('../../../../../assets/images/fan-auto-medium.png');
}

.zone-fan-speed-auto-low {
  background-image: url('../../../../../assets/images/fan-auto-low.png');
}

.zone-fan-off-on {
  background-image: url('../../../../../assets/images/fan-off-on.png');
}

.zone-fan-off-off {
  background-image: url('../../../../../assets/images/fan-off-off.png');
}

/*--------------------------------------------------------------------*/
/*Thermostat User Interface Lockout Icons*/

.zone-lock-0 {
  background-image: url('../../../../../assets/images/lock_0.png');
}

.zone-lock-1 {
  background-image: url('../../../../../assets/images/lock_1.png');
}

.zone-lock-2 {
  background-image: url('../../../../../assets/images/lock_2.png');
}

.zone-lock-3 {
  background-image: url('../../../../../assets/images/lock_5.png');
}

.zone-lock-4 {
  background-image: url('../../../../../assets/images/lock_5.png');
}

.zone-lock-5 {
  background-image: url('../../../../../assets/images/lock_5.png');
}

/*--------------------------------------------------------------------*/
/*Schedule State Icons*/

.zone-schedule-on-on {
  background-image: url('../../../../../assets/images/schedule-on.png');
}

.zone-schedule-on-off {
  background-image: url('../../../../../assets/images/schedule-off.png');
}

.zone-schedule-off-on {
  background-image: url('../../../../../assets/images/schedule-none.png');
}

.zone-schedule-off-off {
  background-image: url('../../../../../assets/images/schedule-none.png');
}

.zone-schedule-eco {
  margin-left: 4px;
  background-image: url('../../../../../assets/images/suitcase-eco.png');
}

.zone-schedule-warn {
  margin-left: 4px;
  background-image: url('../../../../../assets/images/suitcase-warn.png');
}

/*--------------------------------------------------------------------*/
/*Setpoint Limit Profile Icons*/

.zone-profile-property {
  background-image: url('../../../../../assets/images/profile-property.png');
}

.zone-profile-unit {
  background-image: url('../../../../../assets/images/profile-unit.png');
}

.zone-profile-custom {
  background-image: url('../../../../../assets/images/profile-custom.png');
}

.zone-profile-thermostat {
  background-image: url('../../../../../assets/images/profile-thermostat.png');
}

/*--------------------------------------------------------------------*/
/*Containers for the Zone Icons - must be here below the icons above*/

.zone-icon-container {
  margin-top: 8px;
  margin-bottom: 5px;
}

.zone-fan-icon {
  opacity: 0.65;
}

.zone-icon {
  background-size: contain;
  height: 30px;
  width: 30px;
  margin-right: 2px;
}

.zone-icon-help {
  font-size: 11px;
  border-top: 1px solid #36afa5;
  padding-top: 4px;
  margin: 4px;
}

/*--------------------------------------------------------------------*/
/*Coloring for the Zone Icons*/

.zone-hvac-heating {
  background-color: red;
}

.zone-hvac-cooling {
  background-color: lightskyblue;
}

.zone-hvac-off {
  background-color: transparent;
}
