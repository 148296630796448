.recharts-legend-wrapper {
    bottom: 0px !important;
}

.embue-charts-rotated_labels .recharts-xAxis .recharts-cartesian-axis-tick-value {
    translate: -33px 10px;
}

.embue-charts-rotated_labels .recharts-cartesian-axis-ticks g:last-child .recharts-cartesian-axis-tick-value {
    translate: -5px 10px !important;
}
