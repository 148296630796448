div.rdg-header-row > div:first-child {
    width: auto;
}

div.rdg-header-row > div:first-child > span:first-child {
    position: relative;
}

div.rdg-header-row > div:not(:first-child) {
    width: auto;
}

div.subscriptions-update-grid[role="columnheader"] > div:first-child {
    position: relative;
}

div.subscriptions-update-grid[role="columnheader"] > div:nth-child(2) {
    display: none;
}
