.dots {
    width: 40px;
    height: 9.6px;
    background: radial-gradient(circle closest-side,#69b9b5 90%,#0000) 0% 50%,
    radial-gradient(circle closest-side,#69b9b5 90%,#0000) 50% 50%,
    radial-gradient(circle closest-side,#69b9b5 90%,#0000) 100% 50%;
    background-size: calc(100%/3) 100%;
    background-repeat: no-repeat;
    animation: dots-zcf63l 1.4s infinite linear;
    margin-top: 10px;
    margin-left: 10px;
}

@keyframes dots-zcf63l {
    33% {
        background-size: calc(100%/3) 0, calc(100%/3) 100%, calc(100%/3) 100%;
    }

    50% {
        background-size: calc(100%/3) 100%, calc(100%/3) 0, calc(100%/3) 100%;
    }

    66% {
        background-size: calc(100%/3) 100%, calc(100%/3) 100%, calc(100%/3) 0;
    }
}
