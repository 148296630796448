/* Inspired by https://stackoverflow.com/a/74082855/2041135 */
.mainContainer {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    /*margin: 1em;*/
}

.mainContainerError {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    border-left: 2px solid red;
    border-bottom: 2px solid red;
    border-right: 2px solid red;
    border-radius: 5px;
    /*margin: 1em;*/
}

.childrenContainer {
    padding: 4px 14px 16px 14px;
}

.header {
    display: flex;
    flex-direction: row;
    width: 100% !important;
}

.headerBorderBefore {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    width: 0.5em;
    border-top-left-radius: 5px;
}

.headerBorderErrorBefore {
    border-top: 2px solid red;
    width: 0.5em;
    border-top-left-radius: 4px;
}

.headerTitle {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 0.25em;
    width: fit-content;
    height: 2em;
    margin: -1em 0em 0em 0em;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 0.75em;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
}

.headerTitleBubble {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 0.25em;
    width: fit-content;
    height: 2em;
    margin: -1em 0em 0em 0em;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 0.75em;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    padding: 1px;
    background-color: inherit;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.headerBorderAfter {
    border-top: 1px solid rgba(0, 0, 0, 0.2);

    flex-grow: 2;
    border-top-right-radius: 5px;
}

.headerBorderErrorAfter {
    border-top: 2px solid red;

    flex-grow: 2;
    border-top-right-radius: 4px;
}
