.filter-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #f0f0f0;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    transition: box-shadow 0.3s ease;
}

.filter-button:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
}

.filter-button.Mui-selected {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    transform: translateY(1px);
    color: rgb(43 93 47) !important;
    background-color: #1fee44b3 !important;
}
