.rdg-row .rdg-cell.subscribed-alerts-list-expando-expanded {
    padding: 24px;
    background-color: #e4f1e4;
}

.rdg-row .rdg-cell.subscribed-alerts-list-expando-expanded[aria-selected='true'] {
    outline: inherit !important;
    outline-offset: inherit !important;
}

.rdg-row .rdg-cell.subscribed-alerts-list-expando[aria-selected="true"] {
    outline: inherit !important;
    outline-offset: inherit !important;
}

.rdg-header-row .rdg-cell[aria-selected="true"] {
    outline: inherit!important;
    outline-offset: inherit!important;
}
