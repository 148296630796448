.air-handler-loading-container {
  width: 100%;
  height: fit-content;
  border: 1px solid lightgray;
  padding: 0px;
}

.air-handler-loading-2 {
  background-image: url('../../../assets/blurred-thermostat.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 225px;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.air-handling-loading-message {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.MuiCardHeader-avatar { margin-right: 0px !important; }
