.table-cell-expander-formatter-base {
    display: table;
    block-size: 100%;
}

.table-cell-expander-formatter-base > span {
    display: table-cell;
    vertical-align: middle;
    cursor: pointer;
}
