html > body.authenticated {
    display: flex;
    flex: 1;
    align-items: stretch;
}

body.guest {
    background-image: url("../assets/embue-background-low.jpg");
    background-repeat: repeat-y;
    background-size: cover;
    overflow: hidden;
}

body.guest-mobile {
    background-image: url("../assets/embue-background-low.jpg");
    background-color: #9d7780;
    background-repeat: no-repeat;
    background-size: auto;
}

body.authenticated {
    background-image: none;
    background: inherit;
}

body.authenticated #root {
    display: flex;
    flex: 1;
    align-items: stretch;
}
